var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "Other";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;
            if (dataString.indexOf(data[i].subString) !== -1) return data[i].identity;                
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index === -1) return;            
        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) return parseFloat(dataString.substring(rv + 3));
        else return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
    },
    dataBrowser: [
      {string: navigator.userAgent, subString: "Edge", identity: "MS Edge"},
      {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
      {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
      {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
      {string: navigator.userAgent, subString: "Opera", identity: "Opera"},  
      {string: navigator.userAgent, subString: "OPR", identity: "Opera"}, 
      {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"}, 
      {string: navigator.userAgent, subString: "Safari", identity: "Safari"}       
    ]
};    
BrowserDetect.init();
if(BrowserDetect.browser == 'Explorer' || BrowserDetect.browser == 'Trident'){
  document.body.innerHTML = '<div style="font-family: Quicksand;"><h2>Ustøttet nettleser</h2><p>Nettleseren du benytter deg av er ikke støttet av Matrix Innsyn. Vi jobber med å legge til støtte for flere nettlesere, men foreløpig er det disse som støttes:</p><ul><li><a href="https://www.google.com/chrome/">Google Chrome</a></li><li><a href="https://www.mozilla.org/nb-NO/firefox/new/">Firefox</a></li><li><a href="https://www.opera.com/no">Opera</a></li><li><a href="https://www.microsoft.com/en-us/edge">Edge</a></li></ul><p>Bruker en av disse og får denne melding kan årsaken være dine innstillinger. Ta kontakt med oss</p></div>';
}


